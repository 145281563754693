import api from './api';
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');
};

export const getClients = (params) => {
  console.log('Query', queryString(params));
  return api.get('/v1/crm/customers/clients?' + queryString(params));
};
export const getClientDetail = (id) => {
  return api.get('/v1/crm/customers/client/' + id);
};
export const deleteBulkClients = (data) => {
  return api.delete('/v1/crm/customers/clients', { data }, {});
};
export const postClient = (params, token) => {
  return api.post('/v1/crm/customers/client', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const postBulkAssign = (params) => {
  return api.post('/v1/crm/customers/bulk-assign', params, {});
};
export const updateClient = (id, params) => {
  return api.patch('/v1/crm/customers/client/' + id, params);
};

export const updateClientAccess = (id, params) => {
  return api.patch('/v1/crm/customers/clientAccess/' + id, params);
};

export const updateLead = (id, params) => {
  return api.patch('/v1/crm/customers/lead/' + id, params);
};


export const getCustomersDropdown = (query = '', params = {}) => {
  params.query = query;
  return api.get('/v1/crm/customers/dropdown?' + queryString(params));
};
export const getCustomerSearchDropdown = (query) => {
  return api.get('/v1/crm/customers/dropdown?query=' + query);
};

export const searchCustomers = (params) => {
  const { page, limit, ...searchValue } = params;
  const queryString = `page=${page}&limit=${limit}`;
  return api.post(`/v1/crm/customers/search-customers?${queryString}`, searchValue);
};

export const actionNeeded = (params) => {
  const { page, limit, accountStatus, ...searchValue } = params;
  const queryString = `page=${page}&limit=${limit}`;
  return api.post(`/v1/crm/customers/action-needed?${queryString}`, searchValue);
};

export const amlIntegration = (params) => {
  console.log('aml data: ', params);
  return api.post(`/v1/crm/customers/amlIntegration`, params);
};


