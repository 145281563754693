import React, { useState, useEffect } from 'react';
import Input from '../../Shared/Input/Index';
import { Form, FormGroup, Row, Col } from 'reactstrap';
import axios from 'axios';
import CommonModal from '../../../../src/Components/Common/Data/Ui-kits/Modals/common/modal';
import { leadService, activitiesService } from 'Services';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const LeadFormModal = ({ modal, NewMessage, toggle, defaultVal, loadData }) => {
  const [token, setToken] = useState('');

  const userPermissions = useSelector(
    (state) => (state.login && state.login.profile) || {}
);

  useEffect(() => {
    axios
      .get(`https://trial.mobiscroll.com/content/countries.json`)
      .then((resp) => {
        const countries = [];
        for (let i = 0; i < resp.data.length; ++i) {
          const country = resp.data[i];
          countries.push({ text: country.text, value: country.text });
        }
        setCountries(countries);
      });

    const tokenGetting = localStorage.getItem('token');
    setToken(tokenGetting);
  }, []);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(countries[0] && countries[0].value || '');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [, setRec] = useState({});
  const modalSubmit = (e) => {
    e.preventDefault();
    // handle form submission
    handleSubmit();
  };

  const validation = () => {
    if (
      firstName === '' &&
      lastName === '' &&
      email === '' &&
      phoneNumber === '' &&
      country === '' &&
      city === ''
    ) {
      alert('Please fill all fields');
    } else {
      console.log("Country Is ", country);
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phoneNumber,
        country: country,
        city: city,
      };
      leadService
        .postLead(data, token)
        .then((res) => {
          setRec({
            ...res,
            loading: false,
          });

          const logData = {
            type: `New Lead Info Added`,
            userId: userPermissions.id,
            createdAt: Date.now(),
            customerId: res && res.id,
            note: data
        };

        activitiesService.postActivity(logData);

          toast.success('Lead Created Successfully');
          toggle();
          loadData();
        })
        .catch((err) => {
          toast.error('Error in adding Lead ');
        });
    }
  };
  useEffect(() => {
    if (countries.length > 0) {
      console.log("Country ", countries);
      setCountry(countries[0] && countries[0].value);
    }
  }, [countries]);
  const handleSubmit = () => {
    validation();
  };
  return (
    <CommonModal
      isOpen={modal}
      title={NewMessage}
      toggler={toggle}
      formSubmit={modalSubmit}
      isButtonShown={false}
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md='6'>
            <FormGroup>
              <Input
                label='First Name:'
                type='text'
                placeholder='Enter your first name'
                value={firstName}
                onChange={setFirstName}
                required
                elementType='input'
              />
            </FormGroup>
          </Col>
          <Col md='6'>
            <FormGroup>
              <Input
                label='Last Name:'
                type='text'
                placeholder='Enter your last name'
                value={lastName}
                onChange={setLastName}
                required
                minLength={3}
                maxLength={20}
                elementType='input'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <FormGroup>
              <Input
                label='Phone Number:'
                type='text'
                placeholder='Enter your phone number'
                value={phoneNumber}
                onChange={setPhoneNumber}
                minLength={3}
                maxLength={20}
                elementType='input'
              />
            </FormGroup>
          </Col>
          <Col md='6'>
            <FormGroup>
              <Input
                label='Email Address:'
                type='text'
                placeholder='Enter your email'
                value={email}
                pattern={/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/}
                onChange={setEmail}
                required
                minLength={3}
                elementType='input'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <FormGroup>
              <Input
                label='Country:'
                placeholder='Enter your Country'
                value={country}
                onChange={setCountry}
                elementType='select'
                option={countries}
              />
            </FormGroup>
          </Col>
          <Col md='6'>
            <FormGroup>
              <Input
                label='City:'
                type='text'
                elementType='input'
                placeholder='Enter your city'
                value={city}
                onChange={setCity}
                required
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </CommonModal>
  );
};

export default LeadFormModal;
