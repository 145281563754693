import React, { Fragment } from "react";

//import Language from "./Language";
import MoonLight from "./MoonLight";
import Searchbar from "./Searchbar";
import UserHeader from "./UserHeader";
import { UL } from "../../../AbstractElements";

const RightHeader = () => {
  return (
    <Fragment>
      <div className="nav-right col-md-5 col-sm-3 col-3 pull-right right-header p-0 ">
        {/* Showing errror so used col-8 in div need to check it with Anjali Mam */}
        {/* <Col md="8"> */}
        <UL attrUL={{ className: "nav-menus flex-row" }}>
          {/* <Language style={{display: 'none'}}/> */}
          <Searchbar />
          {/* <Notificationbar /> */}
          {/* <BookmarkHeader /> */}
          <MoonLight />
          {/* <CartHeader /> */}
          {/* <ChatHeader /> */}
          {/* <MaxMiniSize /> */}
          <UserHeader />
        </UL>
        {/* </Col> */}
      </div>
    </Fragment>
  );
};

export default RightHeader;
