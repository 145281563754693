const arr = [
  { name: 'Deposit (CBD)', value: 'Deposit (CBD)', key: 1 },
  { name: 'Deposit (USD AfrAsia)', value: 'Deposit (USD AfrAsia)', key: 1 },
  { name: 'Deposit (EUR AfrAsia)', value: 'Deposit (EUR AfrAsia)', key: 1 },
  { name: 'Deposit (AED AfrAsia)', value: 'Deposit (AED AfrAsia)', key: 1 },
  { name: 'Deposit (Rebate Transfer to TP)', value: 'Deposit (Rebate Transfer to TP)', key: 1 },
  { name: 'Deposit (USD In Person)', value: 'Deposit (USD In Person)', key: 1 },
  { name: 'Deposit (USDT Crypto)', value: 'Deposit (USDT Crypto)', key: 1 },
  { name: 'Deposit (USDT - IR Crypto)', value: 'Deposit (USDT - IR Crypto)', key: 1 },
  { name: 'Deposit (Credit In)', value: 'Deposit (Credit In)', key: 1 },
  { name: 'Deposit (others)', value: 'Deposit (others)', key: 1 },
  // { name: 'Deposit (Local LiteFinance Morocco)', value: 'Deposit (Local LiteFinance Morocco)', key: 1 },
  // { name: 'Local Deposit Morocco', value: 'Local Deposit Morocco', key: 1 },
  { name: 'AED Online Deposit', value: 'AED Online Deposit', key: 1 },
  { name: 'USD Online Deposit', value: 'USD Online Deposit', key: 1 },
  { name: 'INR Online Deposit', value: 'INR Online Deposit', key: 1 },
  { name: 'Deposit (ABC Enterprises)', value: 'Deposit (ABC Enterprises)', key: 1 },
  { name: 'Paypal (Deposit)', value: 'Paypal (Deposit)', key: 1 },
  { name: 'Deposit (LBP)', value: 'Deposit (LBP)', key: 1 },
  { name: 'Deposit (Skrill)', value: 'Deposit (Skrill)', key: 1 },
  { name: 'Deposit (Neteller)', value: 'Deposit (Neteller)', key: 1 },
  { name: 'Deposit (Whish Money)', value: 'Deposit (Whish Money)', key: 1 },
  { name: 'Deposit (Axis Bank)', value: 'Deposit (Axis Bank)', key: 1 },
  { name: 'Deposit (Adjustment - Stop out)', value: 'Deposit (Adjustment - Stop out)', key: 1 },
  { name: 'Deposit (Adjustment)', value: 'Deposit (Adjustment)', key: 1 },

  { name: 'Deposit (ICICI)', value: 'Deposit (ICICI)', key: 1 },
  { name: 'Deposit (IDBI)', value: 'Deposit (IDBI)', key: 1 },
  { name: 'Deposit (CBD Mauritius)', value: 'Deposit (CBD Mauritius)', key: 1 },
  { name: 'Deposit India Bank Account', value: 'Deposit India Bank Account', key: 1 },

  { name: 'Deposit Pakistan Bank Account', value: 'Deposit Pakistan Bank Account', key: 1 },
  { name: 'Deposit Equals Money', value: 'Deposit Equals Money', key: 1 },
  { name: 'Deposit - Perfect Money', value: 'Deposit - Perfect Money', key: 1 },
  { name: 'Deposit (YES Bank)', value: 'Deposit (YES Bank)', key: 1 },
  { name: 'Deposit (Lebanon Clients)', value: 'Deposit (Lebanon Clients)', key: 1 },

  { name: 'Deposit (Turkey)', value: 'Deposit (Turkey)', key: 1 },
  { name: 'Deposit (Jordan)', value: 'Deposit (Jordan)', key: 1 },
  { name: 'Deposit (Iraq)', value: 'Deposit (Iraq)', key: 1 },

  { name: 'Withdrawal (Lebanon Clients)', value: 'Withdrawal (Lebanon Clients)', key: 0 },
  { name: 'Withdrawal (YES Bank)', value: 'Withdrawal (YES Bank)', key: 0 },
  { name: 'Withdrawal - Perfect Money', value: 'Withdrawal - Perfect Money', key: 0 },
  { name: 'Withdrawal Pakistan Bank Account', value: 'Withdrawal Pakistan Bank Account', key: 0 },
  { name: 'Withdrawal Equals Money', value: 'Withdrawal Equals Money', key: 0 },
  { name: 'Withdrawal (LBP)', value: 'Withdrawal (LBP)', key: 0 },
  { name: 'Withdrawal (Skrill)', value: 'Withdrawal (Skrill)', key: 0 },
  { name: 'Withdrawal (Neteller)', value: 'Withdrawal (Neteller)', key: 0 },
  { name: 'Withdrawal (Whish Money)', value: 'Withdrawal (Whish Money)', key: 0 },
  { name: 'Withdrawal (Axis Bank)', value: 'Withdrawal (Axis Bank)', key: 0 },
  { name: 'Paypal (Withdrawal)', value: 'Paypal (Withdrawal)', key: 0 },
  // { name: 'Local Withdrawal Morocco', value: 'Local Withdrawal Morocco', key: 0 },
  { name: 'Withdrawal (ABC Enterprises)', value: 'Withdrawal (ABC Enterprises)', key: 0 },
  { name: 'Withdrawal (CBD)', value: 'Withdrawal (CBD)', key: 0 },
  { name: 'Withdrawal (USD AfrAsia)', value: 'Withdrawal (USD AfrAsia)', key: 0 },
  { name: 'Withdrawal (EUR AfrAsia)', value: 'Withdrawal (EUR AfrAsia)', key: 0 },
  { name: 'Withdrawal (AED AfrAsia)', value: 'Withdrawal (AED AfrAsia)', key: 0 },
  { name: 'Withdrawal (USD In Person)', value: 'Withdrawal (USD In Person)', key: 0 },
  { name: 'Withdrawal (USDT Crypto)', value: 'Withdrawal (USDT Crypto)', key: 0 },
  { name: 'Withdrawal (USDT - IR Crypto)', value: 'Withdrawal (USDT - IR Crypto)', key: 0 },
  { name: 'Withdrawal (Credit out)', value: 'Withdrawal (Credit out)', key: 0 },
  { name: 'Withdrawal (others)', value: 'Withdrawal (others)', key: 0 },
  { name: 'Withdrawal in Person', value: 'Withdrawal in Person', key: 0 },
  { name: 'Withdrawal (Zulutrade Profit share)', value: 'Withdrawal (Zulutrade Profit share)', key: 0 },
  { name: 'Withdrawal (FIX API Account)', value: 'Withdrawal (FIX API Account)', key: 0 },
  { name: 'AED Online Withdrawal', value: 'AED Online Withdrawal', key: 0 },
  { name: 'Withdrawal (Adjustment)', value: 'Withdrawal (Adjustment)', key: 0 },
  { name: 'Withdrawal (ICICI)', value: 'Withdrawal (ICICI)', key: 0 },
  { name: 'Withdrawal (IDBI)', value: 'Withdrawal (IDBI)', key: 0 },
  { name: 'Withdrawal (CBD Mauritius)', value: 'Withdrawal (CBD Mauritius)', key: 0 },
  { name: 'Withdrawal India Bank Account', value: 'Withdrawal India Bank Account', key: 0 },

  { name: 'Stopout Adjustment', value: 'Stopout Adjustment', key: 2 },
  { name: 'Dividend (Italian Clients)', value: 'Dividend (Italian Clients)', key: 2},
  { name: 'Bank Wire', value: 'Bank Wire', key: 2 },
  { name: 'Rebate Transfer to TP', value: 'Rebate Transfer to TP', key: 2 },
  { name: 'Other', value: 'Other', key: 2 },
  { name: 'Crypto', value: 'Crypto', key: 2 },
  { name: 'IDFC Wire', value: 'IDFC Wire', key: 2 },
  { name: 'ICICI Wire', value: 'ICICI Wire', key: 2 },

  { name: 'Withdrawal (Turkey)', value: 'Withdrawal (Turkey)', key: 0 },
  { name: 'Withdrawal (Jordan)', value: 'Withdrawal (Jordan)', key: 0 },
  { name: 'Withdrawal (Iraq)', value: 'Withdrawal (Iraq)', key: 0 },
];
export default arr;
