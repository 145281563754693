import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { MENUITEMS } from './Menu';
import { getMenuPerm } from 'utils';
import complianceImage from '../../assets/images/compliance.png';
import ticketImage from '../../assets/images/ticket.png';
import phonebook from '../../assets/images/phonebook.png';

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive, activeClass }) => {
  const CurrentPath = window.location.pathname;
  const { t } = useTranslation();
  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  const imageArray = {
    'Compliance': complianceImage,
    'Tickets': ticketImage,
    'Directory': phonebook
  };

  const toggletNavActive = (item) => {
    if (window.innerWidth <= 991) {
      document.querySelector('.page-header').className = 'page-header close_icon';
      document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon ';
      document.querySelector('.mega-menu-container').classList.remove('d-block');
      if (item.type === 'sub') {
        document.querySelector('.page-header').className = 'page-header ';
        document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper ';
      }
    }
    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };
  const toSlug = (str) => {
    return str
      .toLowerCase()                  // Convert to lowercase
      .replace(/\s+/g, '-')           // Replace spaces with hyphens
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars except hyphens
      .replace(/\-\-+/g, '-')         // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '')             // Trim hyphens from start of text
      .replace(/-+$/, '');            // Trim hyphens from end of text
  };

  return (
    <>
      {MENUITEMS.map((Item, i) => (
        <Fragment key={i}>
          {/* <li className='sidebar-main-title'>
            <div>
              <h6 className='lan-1'>{t(Item.menutitle)}</h6>
              <p className='lan-2'>{t(Item.menucontent)}</p>
            </div>
          </li> */}
          {Item.Items.map((menuItem, i) => (
            <li className='sidebar-list' key={i}>
              {menuItem.type === 'sub' && getMenuPerm(permissions, menuItem.permission) ? (
                <a
                  href='javascript'
                  className={`sidebar-link sidebar-title ${CurrentPath.includes(toSlug(menuItem.title)) ? 'active' : ''}`}
                  onClick={(event) => {
                    event.preventDefault();
                    setNavActive(menuItem);
                    activeClass(menuItem.active);
                  }}>
                  {
                    (menuItem.title === 'Compliance') ?
                      <img src={imageArray[menuItem.title]} alt={`${menuItem.title}`} width='28' className='pe-2' /> :
                      <menuItem.icon />
                  }
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge2 ? <label className={menuItem.badge2}>{menuItem.badgetxt}</label> : ''}
                  <div className='according-menu'>{menuItem.active ? <i className='fa fa-angle-down'></i> : <i className='fa fa-angle-right'></i>}</div>
                </a>
              ) : (
                ''
              )}

              {menuItem.type === 'link' && getMenuPerm(permissions, menuItem.permission) ? (
                <Link
                  to={menuItem.path}
                  className={`sidebar-link sidebar-title link-nav  ${CurrentPath.includes(toSlug(menuItem.title)) ? 'active' : ''}`}
                  onClick={() => toggletNavActive(menuItem)}>
                  {
                    (menuItem.title === 'Tickets' || menuItem.title === 'Directory' ) ?
                      <img src={imageArray[menuItem.title]} alt={`${menuItem.title}`} width='28' className='pe-2' /> :
                      <menuItem.icon />
                  }
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ''}
                </Link>
              ) : (
                ''
              )}

              {menuItem.type === 'blank' && getMenuPerm(permissions, menuItem.permission) ? (
                <Link
                  to={menuItem.path}
                  target={'_blank'}
                  className={`sidebar-link sidebar-title link-nav  ${CurrentPath.includes(toSlug(menuItem.title)) ? 'active' : ''}`}
                  onClick={() => toggletNavActive(menuItem)}>
                  <menuItem.icon />
                  <span>{t(menuItem.title)}</span>
                  {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ''}
                </Link>
              ) : (
                ''
              )}

              {menuItem.children ? (
                <ul
                  className='sidebar-submenu'
                  style={
                    menuItem?.active || CurrentPath.includes(toSlug(menuItem?.title))
                      ? sidebartoogle
                        ? { opacity: 1, transition: 'opacity 500ms ease-in' }
                        : { display: 'block' }
                      : { display: 'none' }
                  }>
                  {menuItem.children.filter(obj => getMenuPerm(permissions, obj.permission)).map((childrenItem, index) => {
                    return (
                      <li key={index}>
                        {childrenItem.type === 'sub' ? (
                          <a
                            href='javascript'
                            className={`sidebar-link sidebar-title ${CurrentPath.includes(toSlug(childrenItem.path.split(`${process.env.PUBLIC_URL}/`)[1])) ? 'active' : ''}`}
                            //className={`${CurrentPath.includes(toSlug(childrenItem?.title)) ? 'active' : ''}`}
                            // className={`${childrenItem.active ? 'active' : ''}`}
                            onClick={(event) => {
                              event.preventDefault();
                              toggletNavActive(childrenItem);
                            }}>
                            {t(childrenItem.title)}
                            <span className='sub-arrow'>
                              <i className='fa fa-chevron-right'></i>
                            </span>
                            <div className='according-menu'>{childrenItem.active ? <i className='fa fa-angle-down'></i> : <i className='fa fa-angle-right'></i>}</div>
                          </a>
                        ) : (
                          ''
                        )}

                        {childrenItem.type === 'link' ? (
                          <Link
                            to={childrenItem.path}
                            className={`${CurrentPath.includes(toSlug(childrenItem?.title)) ? 'active' : ''}`}
                            // className={`${childrenItem.active ? 'active' : ''}`} bonusui
                            onClick={() => toggletNavActive(childrenItem)}>
                            {t(childrenItem.title)}
                          </Link>
                        ) : (
                          ''
                        )}

                        {childrenItem.children ? (
                          <ul
                            className='nav-sub-childmenu submenu-content'
                            style={CurrentPath.includes(toSlug(childrenItem?.title)) || childrenItem.active ? { display: 'block' } : { display: 'none' }}>
                            {childrenItem.children.map((childrenSubItem, key) => (
                              <li key={key}>
                                {childrenSubItem.type === 'link' ? (
                                  <Link
                                    to={childrenSubItem.path}
                                    className={`${CurrentPath.includes(toSlug(childrenItem?.title)) ? 'active' : ''}`}
                                    // className={`${childrenSubItem.active ? 'active' : ''}`}
                                    onClick={() => toggletNavActive(childrenSubItem)}>
                                    {t(childrenSubItem.title)}
                                  </Link>
                                ) : (
                                  ''
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ''
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                ''
              )}
            </li>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;
