import React, { Fragment, useEffect, useState } from 'react';
import { activityService } from 'Services';
import { Table } from 'reactstrap';
import TableLoader from 'CommonElements/Table/TableLoader';
import TableNodata from 'CommonElements/Table/TableNodata';
import TablePagination from "CommonElements/Table/WithoutFilterPagination";
import { useSelector } from "react-redux";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import LogViewModal from './LogViewModal';

const ClientProfile = (props) => {
  const {
    isActive = false,
    customerId = '',
    activeTab, tabNumber
  } = props;
  const [rec, setRec] = useState({});
  const [show, setShow] = useState(false);
  const [logData, setLogData] = useState({});
  const detailClick = (details, content) => {
    setLogData({ details, content });
    setShow(true)
  }

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role) || {}
  );

  const loadData = (page = 1) => {
    activityService.getActivities(customerId, page).then((res) => {
      setRec({
        ...res,
        loading: false,
      })
    }).catch(err => {
    });
  }
  useEffect(() => {
    if (activeTab === tabNumber && isActive) {
      loadData();
    }
  }, [activeTab, isActive, tabNumber])

  const camelCaseToTitleCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, firstChar => firstChar.toUpperCase());
  }
  return (
    <Fragment>
      <div className="table-responsive">
        <Table hover striped>
          <thead>
            <tr>
              <th scope="col">{'#'}</th>
              <th scope="col">{'Type'}</th>
              <th scope="col">{'Details'}</th>
              <th scope="col">{'UserId'}</th>
              <th scope="col">{'Time'}</th>

            </tr>
          </thead>
          {rec.loading && <TableLoader />}
          {!rec.loading && rec.data && rec.data.length === 0 && <TableNodata title="Transactions" />}

          <tbody>
            {
              (permissions.title === 'Admin1' || permissions.title === 'Admin') && rec.data ? rec.data.map((item, id) =>
                <tr key={id}>
                  <th scope="row">{item.id}</th>
                  <td >{camelCaseToTitleCase(item.type)}</td>
                  <td >
                    <span onClick={() => { detailClick(item.details, item.content) }} className="m-2 font-info" style={{ cursor: 'pointer' }}>
                      <i className="fa fa-eye"></i>
                    </span>
                  </td>
                  <td >{item.userId}</td>
                  <td >{item.createdAt}</td>

                </tr>
              ) :
                rec.data && rec.data?.filter((item) => item.type !== 'cpPasswordChange' && item.type !== 'newCpPassword').map((item, id) =>
                  <tr key={id}>
                    <th scope="row">{item.id}</th>
                    <td >{camelCaseToTitleCase(item.type)}</td>
                    <td >
                      <span onClick={() => { detailClick(item.details, item.content) }} className="m-2 font-info" style={{ cursor: 'pointer' }}>
                        <i className="fa fa-eye"></i>
                      </span>
                    </td>
                    <td >{item.userId}</td>
                    <td >{item.createdAt}</td>

                  </tr>
                )
            }
          </tbody>
        </Table>
        <TablePagination data={rec} loadData={loadData} />
      </div>

      <LogViewModal show={show} toggle={() => { setShow(!show) }} data={logData} />
    </Fragment>
  );
};
export default ClientProfile;